import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import html2canvas from 'html2canvas';
import {FormattedMessage} from 'react-intl';

const ResultSubScription = () => {
  const location = useLocation();
  const [resultSubscript, setResultSubscript] = useState(null);

  useEffect(() => {
    if(!resultSubscript) {
      setResultSubscript(location.state);
    }
  }, [location]);

  const capture = () => {
    const body = document.querySelector('body');
    if(body.id !== 'capture') {
      body.id = 'capture';
      html2canvas(document.querySelector("#capture")).then(canvas => {
        document.body.appendChild(canvas);
      }).then(() => {
        var canvas = document.querySelector('canvas');
        canvas.style.display = 'none';
        var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        var a = document.createElement("a");
        a.setAttribute('download', 'myImage.png');
        a.setAttribute('href', image);
        a.click();
      });
    }
  };

  return (
    <div className="success-page">
      <div className="flex mb-4 text-center leading-9">
        <div className="w-full h-12">
          <div className="flex flex-wrap w-full">
            <div className="w-full px-2">
              {resultSubscript && resultSubscript.map(userInfo => {
                return (
                  <div className="bg-white px-4 py-4 flex my-2 rounded-lg shadow" key={userInfo.id}>
                    <div className="w-1/2">
                      <p>{userInfo.name}</p>
                      <p><label className="font-semibold"><FormattedMessage id="form.chair"/>: </label>{userInfo.chair_number}</p>
                    </div>
                    <div className="w-1/2">
                      <div className="h-full" style={{ background: userInfo.questions.status }}>&nbsp;</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="text-center">
            <button 
                className="shadow focus:shadow-outline bg-purple-500 focus:outline-none text-white font-bold py-2 px-4 rounded" 
                type="button"
                onClick={capture}
              >
                <FormattedMessage id="form.download"/>
              </button>
          </div>
        </div>
      </div>    
    </div>
  )
}

export default ResultSubScription;