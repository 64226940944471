import React, { useState, useEffect } from 'react'
import {FormattedMessage} from 'react-intl';
import { db } from '../firebase/firebaseConfig'
import SelectComponent from "./SelectComponent";
import { checkMatchRegex } from "../utils/util";
import TableQuestion from './TableQuestion';

const Alert = ({
  message
}) => <p style={{color: 'red'}}>{message}</p>;

const StarRed = () => <span style={{color: 'red'}}>(*)</span>;


const ContactForm = ({
  keyUser,
  formData,
  nationalityOptions,
  enableAddPeopleButton,
  copyInfoAddress,
  onSetFormData,
  handleAddPeople,
  onDeleteUser,
  handleCopyInfoAddress,
  sendUserInfo
}) => {
    
    const checkValidateName = formData.name == "" ? true : checkMatchRegex(formData.name, "^[a-zA-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$");
    const checkValidatePhone = formData.phone == "" ? true : checkMatchRegex(formData.phone, /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
    const [districtOptions, setDistrictOptions] = useState([])
    const [districtsByProvinceData, setDistrictsByProvinceData] = useState({})
    const [wardOptions, setWardOptions] = useState([])
    const [provinceOptions, setprovinceOptions] = useState({})
    useEffect(() => {
      async function fetchProvincesData() {
        const provinceRef = db.collection('provinces').orderBy('id', 'asc');
        const snapshot = await provinceRef.get()
        const provinces = {}
        snapshot.forEach(doc => {
          provinces[doc.data().code] = doc.data().name
        });
        return provinces;
      }
      fetchProvincesData().then((data) => {
        const pOptions = Object.entries(data).map(([key, province]) => ({ value: key, label: province }))
        setprovinceOptions(pOptions)
      })
    }, [])

    const updateInput = e => {
        onSetFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const onChangeProvince = e => {
      const provinceValue = e.value  // example: SG
      const districtsWardsRef = db.collection('districts_wards').doc(provinceValue)
      districtsWardsRef.get().then((doc) => {
        const district = {}
        const districtArray = []
        Object.entries(doc.data()).map(([districtName, wardsArray]) => (
          district[districtName] = wardsArray
        ))
        Object.entries(doc.data()).map(([districtName, wardsArray]) => (
          districtArray.push({ value: districtName, label: districtName.split("#")[0] })
        ))
        districtArray.sort((a, b) => {
          const aOrder = parseInt(a.value.split("#")[1])
          const bOrder = parseInt(b.value.split("#")[1])
          return aOrder - bOrder;
        })
        setDistrictOptions(districtArray)
        setDistrictsByProvinceData(district)
      })
    }

    const onChangeDistrict = e => {
      const wardsArray = districtsByProvinceData[e.value]
      const wards = []
      wardsArray.map((value) => {
        const concatWard = `${value.prefix} ${value.name}`
        wards.push({ label: concatWard, value: concatWard })
        return concatWard
      })
      setWardOptions(wards)
    }

    const handleSubmit = event => {
      event.preventDefault();
      if(event.target.keyCode == 13) {
        return false;
      }
      sendUserInfo();
    }

    const onHandleAddPeople = event => {
      event.preventDefault();
      handleAddPeople();
    }

    const onCopyInfoAddress = () => {
      handleCopyInfoAddress();
    }

    const onChangeGender = (e) => {
      onSetFormData({
        ...formData,
        gender: e.target.value
      });
    }

    const onchangeAnswer = (e) => {
      let newQuestionList = Object.assign({}, formData.questions);
      newQuestionList = updateNewQuestionsBaseOnFindIndex(newQuestionList, e.target.name, e.target.value);
      onSetFormData({
        ...formData,
        questions: newQuestionList
      });
    }

    const updateNewQuestionsBaseOnFindIndex = (questions, stringCompare, stringUpdate) => {
      let newQuestions = Object.assign({}, questions);
      let indexRecordNeedUpdate = -1;

      newQuestions.list.some((recordQuestion, index) => {
        indexRecordNeedUpdate = recordQuestion.answers.findIndex(obj => obj.name === stringCompare);
        if(indexRecordNeedUpdate !== -1) {
          recordQuestion.answers[indexRecordNeedUpdate].status = stringUpdate;
          return true; // out of questions if found index and updated for this record
        }
      });
      return questions;
    }

    return (
        <form className="w-full" style={{ margin: 'auto' }}>
          {/* section 1 */}
          <div className="flow-root md:items-center mt-8">
              <label className="float-left block font-bold md:text-right mt-2 md:mb-0" htmlFor="inline-full-name">
              <FormattedMessage id="form.information"/>
              </label>
              {keyUser !== 1 && <button 
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteUser();
                }}
                className="float-right bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">
                <i className="far fa-trash-alt"></i> <FormattedMessage id="form.delete"/>
              </button>}
          </div>

          {/* họ và tên */}
          <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                <FormattedMessage id="form.name"/><StarRed/>
              </label>
              </div>
              <div className="md:w-2/3">
              <FormattedMessage id="form.placeholderName" defaultMessage="search">
                {placeholder => <input 
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
                  id="inline-full-name" 
                  type="text"
                  required
                  placeholder={placeholder} 
                  onChange={updateInput}
                  name="name"
                  value={formData.name}
                  autoFocus={true}
                />}
              </FormattedMessage>
              {!checkValidateName && <Alert message={<FormattedMessage id="form.alert.NoSpecifyCharacter"/>}/>}
              </div>
          </div>

          {/* số điện thoại */}
          <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold mb-1 md:mb-0 pr-4" htmlFor="inline-phone">
                  <FormattedMessage id="form.phone"/><StarRed/>
                </label>
              </div>
              <div className="md:w-2/3">
              <FormattedMessage id="form.placeholderPhone" defaultMessage="search">
                {placeholder => <input 
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
                  id="inline-phone" 
                  type="text"
                  name="phone"
                  required
                  value={formData.phone || ""}
                  onChange={updateInput}
                  maxLength={12}
                  placeholder={placeholder}
                />}
              </FormattedMessage>
              </div>
              {!checkValidatePhone && formData.phone && <Alert message={<FormattedMessage id="form.alert.Phone11"/>}/>}
          </div>

          {/* giới tính */}
          <div className="block md:items-center">
            <label className="md:w-2/3 block text-gray-500 font-bold"><FormattedMessage id="form.gender"/><StarRed/></label>
            <div className="flex">
              <div className="flex w-1/3 h-12">
                <input 
                  className="mt-1 leading-tight" 
                  type="radio" 
                  style={{ width: '80px', zIndex: 2 }}
                  name="gender"
                  value="Nam"
                  checked={formData.gender === "Nam"}
                  onChange={onChangeGender}
                />
                <span className="text-sm" style={{ marginLeft: '-30px' }}>
                <FormattedMessage id="form.male"/>
                </span>
              </div>
              <div className="flex w-1/3 h-12">
                <input 
                  className="mt-1 leading-tight" 
                  type="radio" 
                  style={{ width: '80px', zIndex: 2 }}
                  value="Nữ"
                  name="gender"
                  checked={formData.gender === "Nữ"}
                  onChange={onChangeGender}
                />
                <span className="text-sm" style={{ marginLeft: '-30px' }}>
                <FormattedMessage id="form.female"/>
                </span>
              </div>
              <div className="flex w-1/3 h-12" onChange={onChangeGender}>
                <input 
                  className="mt-1 leading-tight" 
                  type="radio" 
                  style={{ width: '80px', zIndex: 2 }}
                  value="Khác"
                  name="gender"
                  checked={formData.gender === "Khác"}
                  onChange={onChangeGender}
                />
                <span className="text-sm" style={{ marginLeft: '-30px' }}>
                <FormattedMessage id="form.another"/>
                </span>
              </div>
            </div>
          </div>

          {/* quốc tịch */}
          <div className="inline-block relative mb-4" style={{ width: '100%' }}>
            <label className="md:w-2/3 block text-gray-500 font-bold"><FormattedMessage id="form.national"/><StarRed/></label>
            <SelectComponent 
              data={nationalityOptions} 
            value={formData.national ? formData.national : nationalityOptions[0]}
              onSelect={(e) => {
                onSetFormData({
                  ...formData,
                  national: e
                });
              }}
            />
          </div>

          {/* số ghế */}
          <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
              <label className="contents text-gray-500 font-bold mb-1 md:mb-0 pr-4" htmlFor="inline-chair">
              <FormattedMessage id="form.chair"/><StarRed/>
              </label>
              </div>
              <div className="md:w-2/3">
              <FormattedMessage id="form.placeholderChair" defaultMessage="search">
              {placeholder => <input 
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
                id="inline-chair" 
                type="text"
                name="num_chair"
                required
                value={formData.num_chair}
                onChange={updateInput}
                placeholder={placeholder}
              />}
              </FormattedMessage>
              </div>
          </div>

          {/* section 2 */}
          <div className="md:flex md:items-center mt-8">
            <div>
              <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
              <FormattedMessage id="form.address"/>
              </label>
            </div>
          </div>

          {keyUser != 1 && <div className="md:flex md:items-center">
            <div className="flex h-12">
              <label>
                <input 
                  className="mt-1 leading-tight" 
                  type="checkbox" 
                  style={{ width: 25 }}
                  name="copyInfoAddress"
                  checked={copyInfoAddress}
                  onChange={onCopyInfoAddress}
                />
                <span className="text-sm">
                <FormattedMessage id="form.reuseInfo"/>
                </span>
              </label>
            </div>
          </div>}

          {/* tỉnh thành */}
          <div className="inline-block relative mb-4" style={{ width: '100%' }}>
            <label className="md:w-2/3 block text-gray-500 font-bold"><FormattedMessage id="form.province"/><StarRed/></label>
            <SelectComponent 
              data={provinceOptions} 
              value={formData.province ? formData.province : null}
              isDisabled={copyInfoAddress}
              onSelect={(e) => {
                onSetFormData({
                  ...formData,
                  province: e,
                  district: null,
                  ward: null,
                  num_home: ""
                });
                onChangeProvince(e);
              }}
            />
          </div>

          {/* quận / huyện */}
          <div className="inline-block relative mb-4" style={{ width: '100%' }}>
            <label className="md:w-2/3 block text-gray-500 font-bold"><FormattedMessage id="form.district"/><StarRed/></label>

            <SelectComponent 
              data={districtOptions} 
              value={formData.district ? formData.district : null}
              isDisabled={copyInfoAddress}
              onSelect={(e) => {
                onSetFormData({
                  ...formData,
                  district: e,
                  ward: null,
                  num_home: ""
                });
                onChangeDistrict(e);
              }}
            />
          </div>

          {/* Phường/xã */}
          <div className="inline-block relative mb-4" style={{ width: '100%' }}>
            <label className="md:w-2/3 block text-gray-500 font-bold"><FormattedMessage id="form.ward"/><StarRed/></label>

            <SelectComponent 
              data={wardOptions} 
              value={formData.ward ? formData.ward : null}
              isDisabled={copyInfoAddress}
              onSelect={(e) => {
                onSetFormData({
                  ...formData,
                  ward: e,
                  num_home: ""
                });
              }}
            />
          </div>

          {/* số nhà, đường */}
          <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
              <label className="contents text-gray-500 font-bold mb-1 md:mb-0 pr-4" htmlFor="inline-home">
              <FormattedMessage id="form.street"/><StarRed/>
              </label>
              </div>
              <div className="md:w-2/3">
              <FormattedMessage id="form.placeholderStreet" defaultMessage="search">
              {placeholder => <input 
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" 
                id="inline-home" 
                type="text"
                name="num_home"
                disabled={copyInfoAddress}
                value={formData.num_home}
                required
                style={{
                  background: copyInfoAddress ? "#f2f2f2" : ""
                }}
                onChange={updateInput}
                placeholder={placeholder}
              />}
              </FormattedMessage>
              </div>
          </div>

          {/* Questions y te */}
          <div className="md:blog w-full mb-6">
            <TableQuestion questions={formData.questions.list} onchangeAnswer={onchangeAnswer}/>
          </div>

          <div className="flex mb-8" style={{ justifyContent: 'space-around' }}>
            <div className="h-12">
              <button 
                className="shadow focus:shadow-outline focus:outline-none text-black font-bold py-2 px-4 rounded" 
                type="button"
                style={{
                  opacity: enableAddPeopleButton ? 1 : 0.2,
                  pointerEvents: enableAddPeopleButton ? 'visible' : 'none'
                }}
                onClick={onHandleAddPeople}
              >
                  <FormattedMessage id="form.button.addPeople"/>
              </button>
            </div>
            <div className="h-12">
              <button 
                className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" 
                type="button"
                onClick={handleSubmit}
                style={{
                  opacity: enableAddPeopleButton ? 1 : 0.2,
                  pointerEvents: enableAddPeopleButton ? 'visible' : 'none'
                }}
              >
                  <FormattedMessage id="form.button.submit"/>
              </button>
            </div>
          </div>
        </form>
        
    )
}

export default ContactForm;