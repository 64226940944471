import React, {useState} from "react";
import { IntlProvider } from "react-intl";

import messages_vi from "./translations/vi.json";
import messages_en from "./translations/en.json";

const Context = React.createContext();

const LIST_MESSAGES = {
  'vi': messages_vi,
  'en': messages_en
};

const IntlProviderWrapper = ({
  children
}) => {
  const [locale, setLocale] = useState("vi");
  const [messages, setMessages] = useState(LIST_MESSAGES["vi"]);

  const switchLanguage = (mode) => {
    setLocale(mode);
    setMessages(LIST_MESSAGES[mode]);
  }

  return (
    <Context.Provider value={{
      locale: locale,
      messages: LIST_MESSAGES[locale],
      switchLanguage: switchLanguage, 
    }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="vi"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext };