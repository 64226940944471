import React, { useState } from 'react'
import { v1 as uuidv1 } from 'uuid';
import { db } from '../firebase/firebaseConfig'
import ContactForm from "./ContactForm";
import { checkMatchRegex, mapColorBaseOnQuestion, formatDataSendToFirebase } from "../utils/util";
import Loading from "../Loading";
import { useHistory } from "react-router-dom";
import { nationalityData_en, nationalityData_vi } from './nationalityData';
import { IntlContext } from "../intl";
import {FormattedMessage} from 'react-intl';
import vietnam from "./vietnam.png";
import english from "./english.png";

const USER_INFO_COLLECTION = 'userinfos';

const LIST_QUESTIONS = [
  { 
    question: <FormattedMessage id="form.questions.question1"/>,
    question_: "Trong vòng 21 ngày qua Anh/Chị có thấy xuất hiện dấu hiệu nào sau đây không ?",
    headers: [<FormattedMessage id="form.questions.header1"/>, <FormattedMessage id="form.questions.header5"/>, <FormattedMessage id="form.questions.header6"/>],
    headers_: ["Triệu chứng", "Có", "Không"],
    answers: [
      { name: "answer-1", status: null, answer: <FormattedMessage id="form.questions.answer1"/>, answer_: "Sốt" },
      { name: "answer-2", status: null, answer: <FormattedMessage id="form.questions.answer2"/>, answer_: "Ho" },
      { name: "answer-3", status: null, answer: <FormattedMessage id="form.questions.answer3"/>, answer_: "Khó thở" },
      { name: "answer-4", status: null, answer: <FormattedMessage id="form.questions.answer4"/>, answer_: "Viêm phổi" },
      { name: "answer-5", status: null, answer: <FormattedMessage id="form.questions.answer5"/>, answer_: "Đau họng" },
      { name: "answer-6", status: null, answer: <FormattedMessage id="form.questions.answer6"/>, answer_: "Mệt mỏi" },
    ]
  },
  { 
    question: <FormattedMessage id="form.questions.question2"/>,
    question_: "Trong vòng 21 ngày qua, Anh/Chị có tiếp xúc với",
    headers: [<FormattedMessage id="form.questions.header2"/>, <FormattedMessage id="form.questions.header5"/>, <FormattedMessage id="form.questions.header6"/>],
    headers_: ["", "Có", "Không"],
    answers: [
      { name: "answer-7", status: null, answer: <FormattedMessage id="form.questions.answer7"/>, answer_: "Người bệnh hoặc nghi ngờ, mắc bệnh COVID-19" },
      { name: "answer-8", status: null, answer: <FormattedMessage id="form.questions.answer8"/>, answer_: "Người từ nước có bệnh COVID-19" },
      { name: "answer-9", status: null, answer: <FormattedMessage id="form.questions.answer9"/>, answer_: "Người có biểu hiện (Sốt, ho, khó thở , Viêm phổi)" },
    ]
  },
];

const ContactContent = () => {
  const history = useHistory();
  const { switchLanguage, locale } = React.useContext(IntlContext);
  const nationalityOptions = locale === "vi" ? nationalityData_vi.map(na => ({ value: na, label: na })) : nationalityData_en.map(na => ({ value: na, label: na }))
  const [currentOpenForm, toggleForm] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState([
    {
      keyUser: 1,
      value: {
        name: "",
        phone: null,
        gender: "Nam",
        national: nationalityOptions[0],
        num_chair: "",
        province: null,
        district: null,
        ward: null,
        num_home: "",
        questions: {
          list: LIST_QUESTIONS,
          status: ""
        },
      },
      copyInfoAddress: false,
      enableAddPeopleButton: false
    }
  ]);

  const togglePanel = (value) => {
    if(currentOpenForm === value) {
      toggleForm(value + 1);
    } else {
      toggleForm(value);
    }
  }

  const isStickAllQuestion = (questions) => {
    let result = true;
    questions.list.some(recordQuestion => {
      if(recordQuestion.answers.findIndex(obj => !obj.status) !== -1) {
        result = false;
        return; // out of questions if found index that not stick
      }
    });
    return result;
  }

  const isValidationAllField = (form) => {
    const checkAvailableOfForm = form.name && form.national && form.province && form.district && form.ward && form.num_home && form.phone && form.num_chair;
    if(checkAvailableOfForm) { // check value of form not null
      if(checkMatchRegex(form.phone, /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) &&
      checkMatchRegex(form.name, "^[a-zA-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$") &&
      isStickAllQuestion(form.questions)
      ) return true;
    }
    return false;
  }

  const onSetFormData = (index, newObject) => {
    let newFormData = Object.assign([], formData);
    newFormData[index].value = newObject;
    newFormData[index].enableAddPeopleButton = isValidationAllField(newObject); // enable submit if validated al
    setFormData(newFormData);
  }

  const handleAddPeople = () => {
    let newFormData = Object.assign([], formData);
     // update status of previous user before add new one
    newFormData[newFormData.length - 1].value.questions.status = mapColorBaseOnQuestion(newFormData[newFormData.length - 1].value.questions);
    let newUserAddToForm = {
      keyUser: newFormData[newFormData.length - 1].keyUser + 1,
      value: {
        name: "",
        phone: null,
        gender: "Nam",
        num_chair: "",
        num_home: "",
        national: nationalityOptions[0],
        province: null,
        district: null,
        ward: null,
        questions: {
          list: [
            { 
              question: <FormattedMessage id="form.questions.question1"/>,
              question_: "Trong vòng 21 ngày qua Anh/Chị có thấy xuất hiện dấu hiệu nào sau đây không ?",
              headers: [<FormattedMessage id="form.questions.header1"/>, <FormattedMessage id="form.questions.header5"/>, <FormattedMessage id="form.questions.header6"/>],
              headers_: ["Triệu chứng", "Có", "Không"],
              answers: [
                { name: "answer-1", status: null, answer: <FormattedMessage id="form.questions.answer1"/>, answer_: "Sốt" },
                { name: "answer-2", status: null, answer: <FormattedMessage id="form.questions.answer2"/>, answer_: "Ho" },
                { name: "answer-3", status: null, answer: <FormattedMessage id="form.questions.answer3"/>, answer_: "Khó thở" },
                { name: "answer-4", status: null, answer: <FormattedMessage id="form.questions.answer4"/>, answer_: "Viêm phổi" },
                { name: "answer-5", status: null, answer: <FormattedMessage id="form.questions.answer5"/>, answer_: "Đau họng" },
                { name: "answer-6", status: null, answer: <FormattedMessage id="form.questions.answer6"/>, answer_: "Mệt mỏi" },
              ]
            },
            { 
              question: <FormattedMessage id="form.questions.question2"/>,
              question_: "Trong vòng 21 ngày qua, Anh/Chị có tiếp xúc với",
              headers: [<FormattedMessage id="form.questions.header2"/>, <FormattedMessage id="form.questions.header5"/>, <FormattedMessage id="form.questions.header6"/>],
              headers_: ["", "Có", "Không"],
              answers: [
                { name: "answer-7", status: null, answer: <FormattedMessage id="form.questions.answer7"/>, answer_: "Người bệnh hoặc nghi ngờ, mắc bệnh COVID-19" },
                { name: "answer-8", status: null, answer: <FormattedMessage id="form.questions.answer8"/>, answer_: "Người từ nước có bệnh COVID-19" },
                { name: "answer-9", status: null, answer: <FormattedMessage id="form.questions.answer9"/>, answer_: "Người có biểu hiện (Sốt, ho, khó thở , Viêm phổi)" },
              ]
            }
          ],
          status: ''
        }
      },
      enableAddPeopleButton: false,
      copyInfoAddress: false
    };
    newFormData.push(newUserAddToForm);
    setFormData(newFormData);
    toggleForm(newUserAddToForm.keyUser);
  }

  const handleCopyInfoAddress = (index) => {
    let newFormData = Object.assign([], formData);
    let newObject = {
      ...newFormData[index].value,
      num_home: newFormData[index].value.num_home == "" ? newFormData[0].value.num_home : "",
      province: newFormData[index].value.province ? null : newFormData[0].value.province,
      district: newFormData[index].value.district ? null : newFormData[0].value.district,
      ward: newFormData[index].value.ward ? null : newFormData[0].value.ward
    }; // newObject have address base on the initial user
    newFormData[index].value = newObject;
    newFormData[index].enableAddPeopleButton = isValidationAllField(newObject);
    newFormData[index].copyInfoAddress = !newFormData[index].copyInfoAddress;
    setFormData(newFormData);
  }

  const onDeleteUser = (index) => {
    let newFormData = Object.assign([], formData);
    const objDeleted = newFormData.splice(index, 1);
    newFormData = newFormData.map((data) => {
      if(data.keyUser > objDeleted[0].keyUser) data.keyUser -= 1;
      return data;
    });
    setFormData(newFormData);
  }

  const sendUserInfo = () => {
    // Get a new write batch
    const batch = db.batch()
    const createdAt = new Date()
    const sessionId = uuidv1();
    let userData = null;
    let userInfoRef = null;
    let userMapped = null;
    let userInfoResult = [];

    setLoading(true);
    formData.map((user) => {
      userData = user.value
      userInfoRef = db.collection(USER_INFO_COLLECTION).doc();

      userMapped = {
        session_id: sessionId,
        id: uuidv1(),
        name: userData.name,
        phone: userData.phone,
        gender: userData.gender,
        nationality: userData.national.label,
        chair_number: userData.num_chair,
        address_detail: {
          "province": userData.province.label,
          "district": userData.district.label,
          "ward": userData.ward.label,
          "street": userData.num_home
        },
        questions: {
          list: formatDataSendToFirebase(userData.questions.list),
          status: userData.questions.status === "" ? mapColorBaseOnQuestion(userData.questions) : userData.questions.status
        },
        province: userData.province.label,
        created_at: createdAt,
      }
      batch.set(userInfoRef, userMapped)
      userInfoResult.push(userMapped);
      return userMapped
    })
    batch.commit().then(() => {
      setFormData([
        {
          keyUser: 1,
          value: {
            name: "",
            phone: null,
            gender: "Nam",
            national: nationalityOptions[0],
            num_chair: "",
            province: null,
            district: null,
            ward: null,
            num_home: "",
            questions: {
              list: [
                { 
                  question: <FormattedMessage id="form.questions.question1"/>,
                  question_: "Trong vòng 21 ngày qua Anh/Chị có thấy xuất hiện dấu hiệu nào sau đây không ?",
                  headers: [<FormattedMessage id="form.questions.header1"/>, <FormattedMessage id="form.questions.header5"/>, <FormattedMessage id="form.questions.header6"/>],
                  headers_: ["Triệu chứng", "Có", "Không"],
                  answers: [
                    { name: "answer-1", status: null, answer: <FormattedMessage id="form.questions.answer1"/>, answer_: "Sốt" },
                    { name: "answer-2", status: null, answer: <FormattedMessage id="form.questions.answer2"/>, answer_: "Ho" },
                    { name: "answer-3", status: null, answer: <FormattedMessage id="form.questions.answer3"/>, answer_: "Khó thở" },
                    { name: "answer-4", status: null, answer: <FormattedMessage id="form.questions.answer4"/>, answer_: "Viêm phổi" },
                    { name: "answer-5", status: null, answer: <FormattedMessage id="form.questions.answer5"/>, answer_: "Đau họng" },
                    { name: "answer-6", status: null, answer: <FormattedMessage id="form.questions.answer6"/>, answer_: "Mệt mỏi" },
                  ]
                },
                { 
                  question: <FormattedMessage id="form.questions.question2"/>,
                  question_: "Trong vòng 21 ngày qua, Anh/Chị có tiếp xúc với",
                  headers: [<FormattedMessage id="form.questions.header2"/>, <FormattedMessage id="form.questions.header5"/>, <FormattedMessage id="form.questions.header6"/>],
                  headers_: ["", "Có", "Không"],
                  answers: [
                    { name: "answer-7", status: null, answer: <FormattedMessage id="form.questions.answer7"/>, answer_: "Người bệnh hoặc nghi ngờ, mắc bệnh COVID-19" },
                    { name: "answer-8", status: null, answer: <FormattedMessage id="form.questions.answer8"/>, answer_: "Người từ nước có bệnh COVID-19" },
                    { name: "answer-9", status: null, answer: <FormattedMessage id="form.questions.answer9"/>, answer_: "Người có biểu hiện (Sốt, ho, khó thở , Viêm phổi)" },
                  ]
                }
              ],
              status: ''
            }
          },
          enableAddPeopleButton: false,
          copyInfoAddress: false
        }
      ]);
      setLoading(false);
      history.push({
        pathname: '/result',
        search: '',
        state: userInfoResult
    });
    })
  }

  const onChangeLanguage = (e, mode) => {
    e.preventDefault();
    switchLanguage(mode);
  }

  return (
    <div className="body-content">
      <div className="flex mb-4 mt-12">
        <div className="w-full flex pl-24 pr-24">
          <img 
              className="w-1/2 mr-1 cursor-pointer"
              src={vietnam} 
              alt="vietnam.png" 
              style={{ opacity: locale === 'vi' ? "1" : "0.3" }}
              onClick={(e) => onChangeLanguage(e, "vi")} 
          />
          <img 
              className="w-1/2 ml-1 cursor-pointer"
              src={english} 
              alt="english.png" 
              style={{ opacity: locale === 'en' ? "1" : "0.3" }}
              onClick={(e) => onChangeLanguage(e, "en")}
          />
        </div>
      </div>
      <div className="flex mb-4 mt-12">
        <div className="w-full">
        <p className="text-sm sm:text-lg md:text-xl lg:text-2xl" style={{ color: "red" }}><FormattedMessage id="form.text.pleaseFillIn"/></p>
          <p className="text-sm sm:text-lg md:text-xl lg:text-2xl"><FormattedMessage id="form.text.starIsMandatory1"/> <span style={{ color: 'red' }}>(*)</span> <FormattedMessage id="form.text.starIsMandatory2"/></p>
        </div>
      </div>
      {isLoading ? <Loading/> : formData.map((form, index) => {
        return (
          <div key={`user${index}`}>
            <div className='header'>
              <div className="flex" onClick={() => togglePanel(form.keyUser)} >
                <FormattedMessage id="form.user" defaultMessage="Người đăng ký">
                  {user => <p className="overflow-hidden w-full">{form.value.name === "" ? `${user} ${form.keyUser}` : form.value.name}</p>}
                </FormattedMessage>
                <svg className={`w-6 h-6 ${form.keyUser === currentOpenForm ? 'rotateLeft180' : 'rotateRight180'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
              </div>
              {currentOpenForm === form.keyUser && <ContactForm
                keyUser={form.keyUser}
                nationalityOptions={nationalityOptions}
                formData={form.value}
                enableAddPeopleButton={form.enableAddPeopleButton}
                copyInfoAddress={form.copyInfoAddress}
                onSetFormData={(newObject) => onSetFormData(index, newObject)}
                handleAddPeople={handleAddPeople}
                handleCopyInfoAddress={() => handleCopyInfoAddress(index)}
                onDeleteUser={() => onDeleteUser(index)}
                sendUserInfo={sendUserInfo}
              />}

            </div>
          </div>
        )
      })}
    
    </div>
  )
}

export default ContactContent;