export const nationalityData_vi = [
    "Việt Nam",
    "Hàn Quốc",
    "Hoa Kỳ",
    "Trung Quốc",
    "Lào",
    "Campuchia",
    "Nga",
    "Nhật Bản",
    "Úc",
    "Thổ Nhĩ Kỳ",
    "Thụy Điển",
    "Thụy Sĩ",
    "Phần Lan",
    "Pháp",
    "Ma Cao (Trung Quốc)",
    "Hồng Kông (Trung Quốc)",
    "Ả Rập Xê Út",
    "Afghanistan",
    "Ai Cập",
    "Albania",
    "Algeria",
    "Ấn Độ",
    "Andorra",
    "Angola",
    "Anh",
    "Antigua và Barbuda",
    "Áo",
    "Argentina",
    "Armenia",
    "Azerbaijan",
    "Ba Lan",
    "Bắc Macedonia",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belize",
    "Bénin",
    "Bhutan",
    "Bỉ",
    "Bờ Biển Ngà",
    "Bồ Đào Nha",
    "Bolivia",
    "Bosnia và Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Các tiểu vương quốc Ả Rập Thống nhất",
    "Cameroon",
    "Canada",
    "Cape",
    "Verde",
    "Chad",
    "Chile",
    "Colombia",
    "Comoros",
    "Cộng hòa dân chủ Congo",
    "Cộng hòa Dominican",
    "Cộng hòa Séc",
    "Cộng hòa Trung Phi",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Djibouti",
    "Dominica",
    "Đài Loan ( Trung Quốc)",
    "Đan Mạch",
    "Đông Timor",
    "Đức",
    "Ecuador",
    "El Salvador",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Gabon",
    "Gambia",
    "Georgia",
    "Ghana",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea Xích đạo",
    "Guinea-Bissau",
    "Guyana",
    "Hà Lan",
    "Haiti",
    "Honduras",
    "Hungary",
    "Hy Lạp",
    "Iceland",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Jamaica",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Latvia",
    "Lesotho",
    "Liban",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Liên bang Micronesia",
    "Lithuania",
    "Luxembourg",
    "Ma-rốc",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mông Cổ",
    "Montenegro",
    "Mozambique",
    "Myanmar",
    "Na Uy",
    "Nam Phi",
    "Nam Sudan",
    "Namibia",
    "Nauru",
    "Nepal",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Qatar",
    "Quần đảo Marshall",
    "Quần đảo Solomon",
    "Romania",
    "Rwanda",
    "Saint Kitts và Nevis",
    "Saint Lucia",
    "Saint Vincent và Grenadines",
    "Samoa",
    "San Marino",
    "São Tomé và Príncipe",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Síp",
    "Slovakia",
    "Slovenia",
    "Somalia",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Tây Ban Nha",
    "Thái Lan",
    "Thành Vatican",
    "Togo",
    "Tonga",
    "Triều Tiên",
    "Trinidad và Tobago",
    "Tunisia",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Ý",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]

export const nationalityData_en = [
    "Vietnamese",
    "Hong Konger (Chinese)",
    "Macau(Chinese)",
    "Taiwanese (Chinese)",
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Barbuda",
    "Argentinian",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamaian",
    "Bahrain",
    "Bangladeshi",
    "Barbadian",
    "Belgian",
    "Belize",
    "Belorussian",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Botswana",
    "Brazilian",
    "Bruneian",
    "Bulgarian",
    "Faso",
    "Burundian",
    "nin",
    "Cambodian",
    "Cameroonian",
    "Canadan",
    "Cape",
    "Republic",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoros",
    "Congolese",
    "Rica",
    "Croatiaan",
    "Cuban",
    "Cypriot",
    "Czech",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican",
    "Dutch",
    "Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirati",
    "English",
    "Guinea",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finn",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadaian",
    "Guatemalan",
    "Bissau",
    "Guinean",
    "Guyanaese",
    "Haitian",
    "Honduran",
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Barbuda",
    "Argentinian",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamaian",
    "Bahrain",
    "Bangladeshi",
    "Barbadian",
    "Belgian",
    "Belize",
    "Belorussian",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Botswana",
    "Brazilian",
    "Bruneian",
    "Bulgarian",
    "Faso",
    "Burundian",
    "nin",
    "Cambodian",
    "Cameroonian",
    "Canadan",
    "Cape",
    "Republic",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoros",
    "Congolese",
    "Rica",
    "Croatiaan",
    "Cuban",
    "Cypriot",
    "Czech",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican",
    "Dutch",
    "Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirati",
    "English",
    "Guinea",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finn",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadaian",
    "Guatemalan",
    "Bissau",
    "Guinean",
    "Guyanaese",
    "Haitian",
    "Honduran",
    "Hungarian",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irishman",
    "Israeli",
    "Italian",
    "Coast",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakh",
    "Kenyan",
    "Kiribati",
    "Korean",
    "Kosovo",
    "Kuwaiti",
    "Kyrgyzstan",
    "Laotian",
    "Latvian",
    "Lesotho",
    "Liban",
    "Liberiam",
    "Libyam",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Madagascan",
    "Malawian",
    "Malaysian",
    "Maldivian",
    "Malian",
    "Maltese",
    "Islands",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesia",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Montenegrin",
    "Moroccan",
    "Mozambican",
    "Myanmarese",
    "Namibian",
    "Nauru",
    "Nepalese",
    "Zealand",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "Korea",
    "Norwegian",
    "Oman",
    "Pakistani",
    "Palau",
    "Palestine",
    "Panamanian",
    "Guinea",
    "Paraguayan",
    "Peruvian",
    "Pole",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Nevis",
    "Lucian",
    "Grenadines",
    "Salvadorean",
    "Samoa",
    "Marino",
    "Saudi",
    "Senegalese",
    "Serbian",
    "Seychelles",
    "Leonean",
    "Singaporean",
    "Slovak",
    "Slovenian",
    "Islands",
    "Somali",
    "Africa",
    "Sudan",
    "Spaiard",
    "Lanka",
    "Sudanese",
    "Surinamese",
    "Swazi",
    "Swede",
    "Swiss",
    "Syrian",
    "ncipe",
    "Tadzhik",
    "Tanzanian",
    "Thai",
    "Togoese",
    "Tongan",
    "Tobago",
    "Tunisian",
    "Turk",
    "Turkmenistan",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbek",
    "Vanuatuan",
    "Vatican",
    "Venezuelan",
    "Verdean",
    "Yemeni",
    "Zambian",
    "Zimbabwean"
];