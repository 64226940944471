import React from 'react';

const Sucess = () => {

    return (
      <div className="success-page">
        <div className="flex mb-4 text-center leading-9">
          <div className="w-full h-12">
            <p className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">Cảm ơn bạn đã xác nhận tham gia</p>
            <p className="sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">Thông tin của bạn đã được lưu thành công</p>  
          </div>
        </div>

        <div className="flex mb-4 text-center leading-9 mt-16">
          <div className="w-full h-12">
            <p className="text-base-title sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">Chúc bạn có một đêm thưởng thức âm nhạc vui vẻ và an lành!</p> 
          </div>
        </div>
        
      </div>
    )
}

export default Sucess;