import React from 'react'

const StarRed = () => <span style={{color: 'red'}}>(*)</span>;

const RadioButton = ({ status, value, name, onchange }) => 
  (<input 
    type="radio" 
    className="form-radio" 
    name={name} 
    value={value} 
    checked={status}
    onChange={onchange}
    style={{
      width: '30px',
      height: '30px'
    }}
  />);

const TableQuestion = ({
  questions,
  onchangeAnswer
}) => {

  return (
    <>
      {questions && questions.map((questionItem, index) => {
        return (
          <div className="mt-8" key={index}>
            <div className="w-full">
              <label className="contents font-bold mb-1 md:mb-0 pr-4" htmlFor="inline-home">
              {questionItem.question}<StarRed/>
              </label>
            </div>
            <div className="w-full">
              <table className="border-collapse border table-auto" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    {questionItem.headers.map((header, index) => <th className="border" key={`${header}${index}`}>{header}</th>)}
                  </tr>
                </thead>
              {questionItem.answers.map(answerItem => {
                return (
                  <tbody key={answerItem.name}>
                    <tr>
                      <td className="border">{answerItem.answer}<StarRed/></td>
                      <td className="border text-center"><RadioButton onchange={onchangeAnswer} status={answerItem.status === 'yes'} value={`yes`} name={answerItem.name}/></td>
                      <td className="border text-center"><RadioButton onchange={onchangeAnswer} status={answerItem.status === 'no'} value={`no`} name={answerItem.name}/></td>
                    </tr>
                  </tbody>
                )
              })}
              </table>
            </div>
          </div>
        )
      })}
      </>
    )
}

export default TableQuestion;