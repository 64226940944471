import './App.css';
import Header from "./contact/Header";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ContactContent from "./contact";
import Success from "./contact/Success";
import "./contact/styled.scss";
import ResultSubScription from './contact/ResultSubscription';
import {  IntlProviderWrapper } from "./intl";

const Container = props =>
  <div
    {...props}
    style={{ margin: '10px' }}
    >
  </div>

function App() {
  return (
    <Container>
      <IntlProviderWrapper>
        <Header/>
        <Router>
          <Route exact path = "/" component = {ContactContent}/>
          <Route path = "/success" component = {Success} />
          <Route path = "/result" component = {ResultSubScription} />
        </Router>
      </IntlProviderWrapper>
   </Container>
  );
}

export default App;
