import React from 'react'
import {FormattedMessage} from 'react-intl';
import logo from "./NHG-logo.png";

const Header = () => {
  return (
    <div className="header-container">

      <div className="flex mb-4 mt-4">
        <div className="w-1/3 h-12"></div>
        <div className="w-1/3 h-12">
          <img src={logo} alt="header-logo.png" className="h-12" style={{ margin: 'auto' }}/>
        </div>
        <div className="w-1/3 h-12"></div>
      </div>

      <div className="flex mb-4 text-center font-extrabold leading-9">
        <div className="w-full h-full">
          <p className="text-base-title sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
          <FormattedMessage id="header.confirmJoin"/>
          </p>
          <p className="text-base-title sm:text-lg md:text-xl lg:text-2xl xl:text-3xl"><FormattedMessage id="header.christmasGift"/></p>  
        </div>
      </div>
    </div>
  )
}

export default Header;