import React from 'react';
import Select from 'react-select'
import {FormattedMessage} from 'react-intl';

const SelectComponent = ({
  data,
  value,
  onSelect,
  isDisabled
}) => {

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      border: value === "" ? '1px solid red' : ""
    }),
    singleValue: (provided, state) => {
      return { ...provided };
    }
  };

  const handleChange = (selectedOption) => {
    // pass function as props to contactForm here
    onSelect(selectedOption);
  }

  return (
    <Select placeholder={<FormattedMessage id="form.placeholderSearch"/>} options={data} value={value} onChange={handleChange} isDisabled={isDisabled} menuColor="red" styles={customStyles}/>
  )
}

export default SelectComponent;