export function checkMatchRegex(value, regex) {
  let string_check = value || "";
  var reg = regex;
  return string_check.match(reg);
}

export const mapColorBaseOnQuestion = (questions) => {
  const COLOR_LIST = {
    RED: 'red',
    BLUE: 'blue'
  };
  let result = COLOR_LIST.BLUE;
  let indexQuestionRecord = -1;
  questions.list.some((recordQuestion, index) => {
    
    indexQuestionRecord = recordQuestion.answers.findIndex(obj => obj.status === 'yes'); // check status record in each question
    if(indexQuestionRecord !== -1) {
      result = COLOR_LIST.RED;
      return true;
    }
  });
  return result;
}

export function resetStatusListQuestion(listQuestion) {
  let newListQuestion = Object.assign([], listQuestion);
  newListQuestion.forEach(recordQuestion => {
    recordQuestion.answers.some(answer => {
      if(answer.status) answer.status = null;
    });
  });
  return newListQuestion;
}

export function formatDataSendToFirebase(listQuestion) {
  let newListQuestion = [];
  
  listQuestion.forEach(recordQuestion => {
    let listNewAnswer = [];
    recordQuestion.answers.forEach(answer => {
      listNewAnswer.push({
        name: answer.name, 
        status: answer.status,
        answer: answer.answer_
      });
    });
    newListQuestion.push({
      question: recordQuestion.question_,
      headers: recordQuestion.headers_,
      answers: listNewAnswer
    });
  });
  return newListQuestion;
}